import React from "react";

import {
  SocialMediaListStyled,
  SocialMediaItemStyled,
  SocialMediaLinkStyled,
  SocialMediaIconStyled,
} from "./styles";

interface ISocialMediaItem {
  name: string;
  href: string;
  icon: React.ReactNode;
  target?: string;
}

interface IProps {
  items: ISocialMediaItem[];
}

const SocialMedia: React.FC<IProps> = ({ items }) => (
  <SocialMediaListStyled>
    {items.map(({ name, href, icon, target }) => (
      <SocialMediaItemStyled key={href}>
        <SocialMediaLinkStyled
          {...{ href, target }}
          rel="noopener"
          aria-label={name}
        >
          <SocialMediaIconStyled>{icon}</SocialMediaIconStyled>
        </SocialMediaLinkStyled>
      </SocialMediaItemStyled>
    ))}
  </SocialMediaListStyled>
);

export default SocialMedia;
