import styled from 'styled-components';
import { media } from './../../common/MediaQueries';

const StyledCopyrights = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  margin-bottom: 20px;

  @media ${media.phone} {
    text-align: right;
  }
`;

const StyledCopyrightsBody = styled.div``;

export { StyledCopyrights, StyledCopyrightsBody };
