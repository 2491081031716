import React from "react";

import { MainNavStyled, MainNavListStyled, MainNavItemStyled, HeaderArrow, SubitemsLine, ItemBackground } from "./styles";
import { IUrlItem } from "./../../interfaces/IUrlItem";
import MainNavItem from "./../../components/MainNavItem";
import SubMenu from "../SubMenu";

export interface IUrlItemList {
  list: IUrlItem[];
}

interface IProps {
  list?: IUrlItem[]
  location: string
}

const MainNav: React.FC<IProps> = ({ list, location }) => {
  return (
    <MainNavStyled>
      {list && (
        <MainNavListStyled>
          {list.map((item: IUrlItem) => {
            const { id, url, title, hideOnDesktop, subitems} = item; 

            return (
              <MainNavItemStyled
                key={`${url}${title}`}
                hideOnDesktop={hideOnDesktop}
                hasSubitems={Boolean(subitems)}
              >
                {subitems && 
                  <>
                    <SubMenu
                      items={subitems}
                      location={location}
                    />
                    <ItemBackground />
                  </>
                }
                <MainNavItem
                  item={item}
                  active={id === location}
                  childActive={Boolean(subitems?.some(item => item.id === location))}
                />
                {subitems && 
                  <>
                    <HeaderArrow>
                      <svg
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82322 13.2374C7.53033 12.9445 7.53033 12.4697 7.82322 12.1768L8.17678 11.8232C8.46967 11.5303 8.94454 11.5303 9.23744 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z"
                          fill="#414141"
                        />
                      </svg>
                    </HeaderArrow>
                    <SubitemsLine />
                  </>
                }
              </MainNavItemStyled>
            )
          })}
        </MainNavListStyled>
      )}
    </MainNavStyled>
  );
};

export default MainNav;
