import '../fonts/fonts.css';

const theme = {
  colors: {
    main: '#012147',
    black: '#000',
    white: '#fff',
    watermelon: '#FF5070',
    salmon: '#F1294E',
    grey: '#D8D8D8',
    grey2: '#979797',
    greyLight: 'rgba(65,65,65,0.05)',
    greyLight2: '#fcfcfc',
    greyDark: '#5D5B65',
    dark: '#414141',
    blue: '#38b1eb',
    blueLight: '#38b0eb',
    blueDark: '#002147',
    red: '#b90e0a',
    redLight: '#Ff4545',
    green: '#32CD32',
    sky: '#658AC8',
    turquoise: '#32F6BF'
  },
  gradients: {
    main: 'linear-gradient(137deg, #012147 0%, #38b1eb 100%)',
    watermelon: 'linear-gradient(180deg, #ff88a9 0%, #ff5070 100%)',
    mist: 'linear-gradient(91.45deg, rgba(236, 94, 115, 0.25) 0%, rgba(248, 125, 248, 0.25) 43.02%, rgba(116, 143, 248, 0.25) 75.49%, rgba(99, 181, 245, 0.25) 100%)',
  },
  breakpoints: ['768px', '1025px', '1440px'],
  maxBreakpoints: ['767px', '1024px', '1439px'],
  sizes: {
    inner: '1354px',
    innerSm: '1084px',
    innerM: '1232px',
    offset: '25px',
  },
  fontFamilies: {
    montserrat: `'Montserrat', sans-serif;`,
    proximaNova: `'proxima-nova', sans-serif;`,
  },
  // Base Size: 16px
  textStyles: {
    heading1: {
      fontSize: ['1.875rem', '3.75rem'],
      lineHeight: ['1.2', '1.1'],
      fontFamily: 'proxima-nova',
    },
    heading2: {
      fontSize: ['1.375rem', '2.1875rem'],
      lineHeight: ['1.2'],
    },
    heading3: {
      fontSize: ['1.5rem', '1.75rem'],
      lineHeight: '1.2',
      letterSpacing: '-0.28px',
    },
    heading4: {
      fontSize: '1.875rem',
      lineHeight: '1.2',
    },
    heading5: {
      fontSize: '3.125rem',
      lineHeight: '1.1',
      letterSpacing: '-0.5px',
    },
    heading6: {
      fontSize: '2.5rem',
      lineHeight: '1.1',
      letterSpacing: '-0.4px',
    },
    heading7: {
      fontSize: '2.0625rem',
      lineHeight: '1.2',
      letterSpacing: '-0.3px',
    },
    heading8: {
      fontSize: '3.125rem',
      lineHeight: '1.3',
      letterSpacing: '-0.5px',
    },
    heading9: {
      fontSize: ['36px', '66px'],
      lineHeight: '1',
      fontFamily: 'proxima-nova',
    },
    heading10: {
      fontSize: ['30px', '40px'],
      lineHeight: '1',
      fontFamily: 'proxima-nova',
    },
    heading11: {
      fontSize: ['40px', '50px'],
      lineHeight: '1',
      fontFamily: 'proxima-nova',
    },
    heading12: {
      fontSize: '1.875rem',
      lineHeight: '1.2',
      fontFamily: 'proxima-nova',
    },
    heading13: {
      fontSize: ['28px', '40px'],
      lineHeight: '1.2',
      letterSpacing: '-2px',
      fontFamily: 'proxima-nova',
    },
    /* new styles  */
    heading14: {
      fontSize: ['18px', '35px'],
      lineHeight: ['29px', '42px'],
      letterSpacing: ['0.0032em', ''],
    },
    heading15: {
      fontSize: ['16px', '16px', '16px', '18px'],
      lineHeight: ['22px', '22px', '22px', '29px'],
      letterSpacing: ['-0.0032em', '-0.0032em', '-0.0032em', '0.0032em'],
    },
    heading16: {
      fontSize: ['16px', '30px'],
      lineHeight: ['22px', '36px'],
    },
    heading17: {
      fontSize: ['16px', '24px'],
      lineHeight: ['22px', '31px'],
    },
    heading18: {
      fontSize: ['16px', '22px'],
      lineHeight: ['22px', '29px'],
    },
    heading19: {
      fontSize: ['16px', '16px', '30px'],
      lineHeight: ['22px', '22px', '36px'],
    },
    heading20: {
      fontSize: ['1.375rem', '1.8rem'],
      lineHeight: ['1.2'],
    },
    /* end new styles */
    typo1: {
      fontSize: '1.125rem',
      lineHeight: '1.6',
      letterSpacing: '0.32px',
    },
    typo2: {
      fontSize: '0.875rem',
      lineHeight: '1.56',
    },
    typo3: {
      fontSize: ['0.625rem', '0.875rem'],
      lineHeight: '1.2',
    },
    typo4: {
      fontSize: ['0.875rem', '1rem'],
      lineHeight: ['1.6', '1.4'],
    },
    typo5: {
      fontSize: '0.875rem',
      lineHeight: '1.7',
    },
    typo6: {
      fontSize: ['1.25rem', '1.5rem'],
      lineHeight: ['1.6', '1.3'],
    },
    typo7: {
      fontSize: '1rem',
      lineHeight: '1.5',
    },
    typo8: {
      fontSize: '0.75rem',
      lineHeight: '1.8',
    },
    typo9: {
      fontSize: ['24px', '37px'],
      lineHeight: '1',
      fontFamily: 'proxima-nova',
    },
    typo10: {
      fontSize: '20px',
      lineHeight: '1.6',
      letterSpacing: '-0.2px',
    },
    typo11: {
      fontSize: ['25px', '30px'],
      lineHeight: ['1.4', '1.2'],
      fontFamily: 'proxima-nova',
    },
    typo12: {
      fontSize: '1rem',
      lineHeight: '1.3',
      fontFamily: 'proxima-nova',
    },
    typo13: {
      fontSize: ['18px', '22px'],
      lineHeight: '1.3',
      fontFamily: 'proxima-nova',
    },
    // New Pages Styles
    typo14: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    typo15: {
      fontSize: '14px',
      lineHeight: '17px',
    },
    typo16: {
      fontSize: '14px',
      lineHeight: '22px',
    },
    typo17: {
      fontSize: '17px',
      lineHeight: '24px',
    },
    typo18: {
      fontSize: ['14px', '16px'],
      lineHeight: ['17px', '22px'],
    },
    typo19: {
      fontSize: ['16px'],
      lineHeight: ['22px', '24px'],
    },
    typo20: {
      fontSize: '18px',
      lineHeight: '22px',
    },
    typo21: {
      fontSize: ['14px', '16px'],
      lineHeight: ['17px', '22px'],
    },
    typo22: {
      fontSize: ['12px'],
      lineHeight: ['22px'],
    },
    typo23: {
      fontSize: ['14px', '16px'],
      lineHeight: ['24px'],
    },
    typo24: {
      fontSize: ['16px', '20px'],
      lineHeight: ['22px', '32px'],
    },
    typo25: {
      fontSize: ['14px', '16px'],
      lineHeight: ['22px', '24px'],
    },
    typo26: {
      fontSize: ['12px', '14px'],
      lineHeight: ['22px'],
    },
    typo27: {
      fontSize: ['14px', '18px'],
      lineHeight: ['22px'],
    },
    typo28: {
      fontSize: ['14px', '16px'],
      lineHeight: ['17px', '24px'],
    },
    typo29: {
      fontSize: ['14px', '18px'],
      lineHeight: ['22px', '29px'],
    },
    typo30: {
      fontSize: ['14px', '20px'],
      lineHeight: ['22px', '32px'],
    },
    typo31: {
      fontSize: ['14px', '20px', '28px', '30px'],
      lineHeight: ['22px', '28px', '34px', '36px'],
    },
    typo32: {
      fontSize: ['16px', '16px', '35px'],
      lineHeight: ['22px', '22px', '42px'],
    },
    typo33: {
      fontSize: ['14px', '14px', '16px'],
      lineHeight: ['22px'],
    },
    typo34: {
      fontSize: ['16px', '16px'],
      lineHeight: ['21px'],
    },
    typo35: {
      fontSize: ['16px', '16px', '28px'],
      lineHeight: ['22px', '22px', '34px'],
    },
    typo36: {
      fontSize: ['12px', '12px', '14px'],
      lineHeight: ['17px', '17px', '17px'],
    },
    typo37: {
      fontSize: ['16px', '16px', '18px'],
      lineHeight: ['22px', '22px', '22px'],
    },
    typo38: {
      fontSize: ['14px', '14px', '20px'],
      lineHeight: ['22px', '22px', '32px'],
    },
    typo39: {
      fontSize: ['16px', '16px', '18px', '20px'],
      lineHeight: ['24px', '24px', '24px', '24px'],
    },
    typo40: {
      fontSize: ['14px', '14px', '16px', '18px'],
      lineHeight: ['22px'],
    },
    typo41: {
      fontSize: ['14px', '18px'],
      lineHeight: ['22px', '24px'],
    },
    typo42: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  fontSize: {
    fontSize12: '12px',
    fontSize14: '14px',
    fontSize15: '15px',
    fontSize18: '18px',
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  lineHeight: {
    big: 1.5,
    medium: 1.25,
    small: 1.15,
    normal: 1,
  },
  btn: {
    width: '168px',
    heightSmall: '28px',
    heightMedium: '49px',
    heightBig: '60px',
    primaryBgHover: '#e72934',
    secondaryBgHover: '#e1ecF2',
  },
  opacities: {
    opacity20: 0.2,
    opacity60: 0.6,
  },
  gutter: {
    gutter8: '8px',
    gutter16: '16px',
    gutter12: '12px',
    gutter24: '24px',
    gutter32: '32px',
    gutter36: '36px',
    gutter40: '40px',
    gutter48: '48px',
    gutter52: '52px',
    gutter64: '64px',
  },
  form: {
    fieldHeight: '54px',
    checkboxSize: '20px',
    checkboxMarkWidth: '6px',
    checkboxMarkHeight: '11px',
    radioMarkSize: '8px',
  },
  headerHeight: {
    mobile: '55px',
    desktop: '89px',
  },
  divider: {
    normal: '1127px',
    large: '1232px',
  },
  zIndex: {
    index10: '10',
  },
};

export default theme;

export type Theme = typeof theme;

export type WithTheme<T = object> = { theme: Theme } & T;
