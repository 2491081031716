import styled, { css } from 'styled-components';
import { media } from './../../common/MediaQueries';

const FooterWrapperStyled = styled.div`
  width: 100%;
`;

interface IFooterStyled {
  skipBorder?: boolean;
  borderOnMobile?: boolean;
  mobileMargin?: number;
}

const FooterStyled = styled.footer<IFooterStyled>`
  width: 100%;
  padding: 30px 0 12px;
  ${({ borderOnMobile }) => (borderOnMobile ? 'border-top: 1px solid #0b264c1a;' : '')}
  ${({ mobileMargin }) => (mobileMargin ? `margin-top: ${mobileMargin}px;` : '')}

  @media ${media.phone} {
    display: flex;
    justify-content: space-between;
    padding: 46px 0 30px 0;
    margin-top: 0;
    ${({ skipBorder }) => !skipBorder && 'border-top: 1px solid #0b264c1a;'}
  }
`;

const FooterItemLogoStyled = styled.div`
  margin-right: 76px;
  margin-bottom: 40px;

  @media ${media.phone} {
    margin-bottom: 0;
  }
`;

interface FooterItemStyledProps {
  grow?: boolean;
  alignRight?: boolean;
}

const FooterItemStyled = styled.div<FooterItemStyledProps>`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${media.phone} {
    margin-bottom: 0;

    ${({ grow }) =>
      grow &&
      css`
        flex-grow: 1;
      `}

    ${({ alignRight }) =>
      alignRight &&
      css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `}
  }
`;

const FooterItemDescriptionStyled = styled.div`
  width: 100%;
  max-width: 300px;
`;

interface FooterItemActionStyledProps {
  tight?: boolean;
  alignRight?: boolean;
}

const FooterItemActionStyled = styled.div<FooterItemActionStyledProps>`
  margin-bottom: 1rem;

  ${({ tight }) =>
    tight &&
    css`
      @media ${media.phone} {
        margin-bottom: 0.5rem;
      }
    `}

  ${({ alignRight }) =>
    alignRight &&
    css`
      @media ${media.phone} {
        display: flex;
        justify-content: flex-end;
      }
    `}
`;

export const FooterLink = styled.a`
  &:hover {
    opacity: 0.85;
  }
`;

const FooterItemSocialsStyled = styled.div`
  margin-bottom: 1rem;

  @media ${media.phone} {
    margin-left: 40px;
    margin-bottom: 0;
  }
`;

export const FooterExtraWrapper = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    text-align: left;
    margin-top: 12px;
    padding-bottom: 0;
    padding: 0 40px;
  }
`;

export const FooterExtraHeading = styled.div`
  margin-bottom: 0;
`;

export const FooterExtraLink = styled.div`
  &:hover {
    opacity: 0.85;
  }
`;

export {
  FooterWrapperStyled,
  FooterStyled,
  FooterItemStyled,
  FooterItemLogoStyled,
  FooterItemDescriptionStyled,
  FooterItemActionStyled,
  FooterItemSocialsStyled,
};
