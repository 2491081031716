import styled from "styled-components";
import {
  space,
  textAlign,
  color,
  position,
  overflow,
  width,
  background,
  backgroundImage,
  zIndex,
} from "styled-system";

const Container = styled.div`
  ${space};
  ${color};
  ${textAlign};
  ${position};
  ${overflow};
  ${width};
  ${backgroundImage};
  ${background};
  ${zIndex};
  position: relative;
  width: 100%;
`;

export default Container;
