import { IUrlItem } from "./../interfaces/IUrlItem";

const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';

export const navigationMenu: IUrlItem[] = [
  {
    id: 'companies',
    title: "For Companies",
    url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/companies/`,
    subitems: [
      { 
        id: 'hire-wise',
        title: 'HireWise',
        url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/hire-wise/`,
      },
      {
        id: 'get-started',
        title: 'TalentMatch',
        url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/talent-match/`
      },
      {
        id: 'plans-hire-wise',
        title: 'Plans | HireWise',
        url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/plans-hire-wise/`,
      },
      {
        id: 'plans-talent-match',
        title: 'Plans | TalentMatch',
        url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/plans-talent-match/`,
      },
    ]
  },
  {
    id: 'recruiters',
    title: 'For Recruiters',
    url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/hire-club/`,
    subitems: [
      { 
        id: 'hire-club',
        title: 'HireClub',
        url: `${protocol}://${process.env.GATSBY_PUBLIC_DOMAIN}/hire-club/`,
      },
    ]
  },
  {
    id: 'candidates',
    title: "For Candidates",
    url: `/get-started/`,
    subitems: [
      {
        id: 'candidates',
        title: "Get Started",
        url: "/get-started/",
      },
      {
        id: 'success',
        title: "Success Stories",
        url: "/success-stories/",
      },
    ]
  },
  {
    id: 'jobs',
    title: "Jobs",
    url: "/jobs/",
  },
  {
    id: 'about',
    title: "About Us",
    url: "/about-us/",
  },
  {
    id: 'blog',
    title: "Blog",
    url: "/blogs/",
  },
];
