import React from "react";
import { ISocialLinks } from "./../interfaces/ISocialLinks";

import Twitter from "./../assets/svg/Twitter";
import LinkedIn from "./../assets/svg/Linkedin";
import Facebook from "./../assets/svg/Facebook";

export const socialLinks: ISocialLinks[] = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/FlatWorldCo",
    icon: <Facebook />,
    target: "_blank",
  },
  {
    name: "Twitter",
    href: "https://twitter.com/FlatWorldCo",
    icon: <Twitter />,
    target: "_blank",
  },
  {
    name: "Linkedin",
    href: "https://www.linkedin.com/company/flatworldco/",
    icon: <LinkedIn />,
    target: "_blank",
  },
];
