import React, { useState, useEffect } from 'react';

import {
  HeaderWrapperStyled,
  HeaderStyled,
  HeaderLeftStyled,
  HeaderRightStyled,
  HeaderLogoStyled,
  HeaderNavigationWrapperStyled,
  HeaderNavigationStyled,
  HeaderNavigationStyledMenu,
  HeaderNavigationStyledSocial,
  HeaderNavigationWrapStyled,
} from './styles';
import Inner from './../Inner';
import Logo from './../Logo';
import MainNav from './../MainNav';
import Burger from './../Burger';
import { navigationMenu } from './../../mocks/navigationMenu';
import useUpdate from '../../hooks/useUpdate';
import useWindowSize from '../../hooks/windowSize';
import HeaderCTA from '../HeaderCTA';

interface IProps {
  location: string;
}

const TABLET_BREAKPOINT = 1025;

const Header: React.FC<IProps> = ({ location }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [pageScrolled, setPageScrolled] = useState(false);

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  function toggleMenuHandler() {
    setMenuOpen(!menuOpen);
  }

  function changeBackground() {
    if (!document.scrollingElement) return;

    if (document.scrollingElement.scrollTop >= 110) {
      setPageScrolled(true);
    } else {
      setPageScrolled(false);
    }
  }

  useUpdate(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [menuOpen]);

  useUpdate(() => {
    if (windowWidth <= TABLET_BREAKPOINT) {
      const root = document.querySelector(':root') as HTMLElement;

      root.style.setProperty('--vh', window.innerHeight / 100 + 'px');
    } else {
      document.body.style.overflow = '';
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    window.addEventListener('scroll', changeBackground, { passive: true });

    return () => window.removeEventListener('scroll', changeBackground);
  }, []);

  return (
    <HeaderWrapperStyled isPageScrolled={pageScrolled ? true : false}>
      <Inner>
        <HeaderStyled>
          <HeaderLeftStyled>
            <HeaderLogoStyled>
              <Logo href="/" />
            </HeaderLogoStyled>
          </HeaderLeftStyled>
          <HeaderRightStyled>
            <HeaderNavigationWrapperStyled>
              <HeaderNavigationStyled isOpened={menuOpen}>
                <HeaderNavigationWrapStyled>
                  <HeaderNavigationStyledMenu>
                    <MainNav list={navigationMenu} location={location} />
                  </HeaderNavigationStyledMenu>
                  <HeaderNavigationStyledSocial>
                    <HeaderCTA />
                  </HeaderNavigationStyledSocial>
                </HeaderNavigationWrapStyled>
              </HeaderNavigationStyled>
              <Burger isOpened={menuOpen} clickHandler={toggleMenuHandler} />
            </HeaderNavigationWrapperStyled>
          </HeaderRightStyled>
        </HeaderStyled>
      </Inner>
    </HeaderWrapperStyled>
  );
};

export default Header;
