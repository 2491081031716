import styled from "styled-components";

import theme from "../../themes/theme";
import DynamicComponent, { ITypoStyles } from "../../common/DynamicComponent";

const {
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  heading7,
  heading8,
  heading9,
  heading10,
  heading11,
  heading12,
  heading13,
  heading14,
  heading15,
  heading16,
  heading17,
  heading18,
  heading19,
  heading20,
  typo1,
  typo2,
  typo3,
  typo4,
  typo5,
  typo6,
  typo7,
  typo8,
  typo9,
  typo10,
  typo11,
  typo12,
  typo13,
  typo14,
  typo15,
  typo16,
  typo17,
  typo18,
  typo19,
  typo20,
  typo21,
  typo22,
  typo23,
  typo24,
  typo25,
  typo26,
  typo27,
  typo28,
  typo29,
  typo30,
  typo31,
  typo32,
  typo33,
  typo34,
  typo35,
  typo36,
  typo37,
  typo38,
  typo39,
  typo40,
  typo41,
  typo42,
} = theme.textStyles;

const createTypoComponent = (typoStyles: ITypoStyles) => {
  const TypoComponent = styled(DynamicComponent)``;

  TypoComponent.defaultProps = {
    ...typoStyles,
  };

  return TypoComponent;
};

export const Heading1 = createTypoComponent(heading1);
export const Heading2 = createTypoComponent(heading2);
export const Heading3 = createTypoComponent(heading3);
export const Heading4 = createTypoComponent(heading4);
export const Heading5 = createTypoComponent(heading5);
export const Heading6 = createTypoComponent(heading6);
export const Heading7 = createTypoComponent(heading7);
export const Heading8 = createTypoComponent(heading8);
export const Heading9 = createTypoComponent(heading9);
export const Heading10 = createTypoComponent(heading10);
export const Heading11 = createTypoComponent(heading11);
export const Heading12 = createTypoComponent(heading12);
export const Heading13 = createTypoComponent(heading13);
export const Heading14 = createTypoComponent(heading14);
export const Heading15 = createTypoComponent(heading15);
export const Heading16 = createTypoComponent(heading16);
export const Heading17 = createTypoComponent(heading17);
export const Heading18 = createTypoComponent(heading18);
export const Heading19 = createTypoComponent(heading19);
export const Heading20 = createTypoComponent(heading20);

export const Typo1 = createTypoComponent(typo1);
export const Typo2 = createTypoComponent(typo2);
export const Typo3 = createTypoComponent(typo3);
export const Typo4 = createTypoComponent(typo4);
export const Typo5 = createTypoComponent(typo5);
export const Typo6 = createTypoComponent(typo6);
export const Typo7 = createTypoComponent(typo7);
export const Typo8 = createTypoComponent(typo8);
export const Typo9 = createTypoComponent(typo9);
export const Typo10 = createTypoComponent(typo10);
export const Typo11 = createTypoComponent(typo11);
export const Typo12 = createTypoComponent(typo12);
export const Typo13 = createTypoComponent(typo13);
export const Typo14 = createTypoComponent(typo14);
export const Typo15 = createTypoComponent(typo15);
export const Typo16 = createTypoComponent(typo16);
export const Typo17 = createTypoComponent(typo17);
export const Typo18 = createTypoComponent(typo18);
export const Typo19 = createTypoComponent(typo19);
export const Typo20 = createTypoComponent(typo20);
export const Typo21 = createTypoComponent(typo21);
export const Typo22 = createTypoComponent(typo22);
export const Typo23 = createTypoComponent(typo23);
export const Typo24 = createTypoComponent(typo24);
export const Typo25 = createTypoComponent(typo25);
export const Typo26 = createTypoComponent(typo26);
export const Typo27 = createTypoComponent(typo27);
export const Typo28 = createTypoComponent(typo28);
export const Typo29 = createTypoComponent(typo29);
export const Typo30 = createTypoComponent(typo30);
export const Typo31 = createTypoComponent(typo31);
export const Typo32 = createTypoComponent(typo32);
export const Typo33 = createTypoComponent(typo33);
export const Typo34 = createTypoComponent(typo34);
export const Typo35 = createTypoComponent(typo35);
export const Typo36 = createTypoComponent(typo36);
export const Typo37 = createTypoComponent(typo37);
export const Typo38 = createTypoComponent(typo38);
export const Typo39 = createTypoComponent(typo39);
export const Typo40 = createTypoComponent(typo40);
export const Typo41 = createTypoComponent(typo41);
export const Typo42 = createTypoComponent(typo42);
