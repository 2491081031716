import { useEffect, useRef } from "react";

export default function useUpdate(func: Function, deps: Array<any>) {
  const first = useRef(true);

  useEffect(() => {
    if (first.current) {
      first.current = false;

      return;
    }

    const cleanup = func();

    return typeof cleanup === "function" ? cleanup : null;
  }, deps);
}
