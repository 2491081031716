import styled, { css } from 'styled-components';

import { media } from '../../common/MediaQueries';
import { transition } from '../../common/mixins';

const HamburgerLineStyled = styled.span`
  position: relative;
  display: flex;
  flex-shrink: 0;
  height: 2px;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: currentColor;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    ${transition}
  }

  &::before {
    top: 5px;
  }

  &::after {
    bottom: 5px;
  }
`;

const HamburgerStyled = styled.button`
  position: relative;
  display: flex;
  width: 18px;
  height: 12px;
  z-index: 100;
  transform: translateZ(0);
  cursor: pointer;

  background-color: transparent;
  color: #ff5070;

  ${({ isOpened }) =>
    isOpened &&
    css`
      ${HamburgerLineStyled} {
        background-color: transparent;
        transform-origin: 50% 50%;
      }

      ${HamburgerLineStyled} {
        &::before {
          top: 0;
          transform: rotate3d(0, 0, 1, -45deg);
        }
      }

      ${HamburgerLineStyled} {
        &::after {
          bottom: 0;
          transform: rotate3d(0, 0, 1, 45deg);
        }
      }
    `}

  @media ${media.tablet} {
    display: none;
  }
`;

export { HamburgerStyled, HamburgerLineStyled };
