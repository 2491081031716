import React from "react";
import CookieConsent from "react-cookie-consent";

import { CookieBarStyled } from "./styles";
import Inner from "./../Inner";

interface IProps {
  cookieName: string;
  cookieExpire: number;
  contentText: React.ReactNode;
  buttonText: string;
}

const CookieBar: React.FC<IProps> = ({
  cookieName,
  cookieExpire,
  contentText,
  buttonText,
}) => (
  <CookieBarStyled>
    <Inner>
      <CookieConsent
        buttonText={buttonText}
        buttonClasses="cookieConsent__button"
        contentClasses="cookiesConsent__text"
        cookieName={cookieName}
        expires={cookieExpire}
        disableStyles
      >
        {contentText}
      </CookieConsent>
    </Inner>
  </CookieBarStyled>
);

export default CookieBar;
