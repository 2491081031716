import React, { ReactNode, useEffect } from "react";

import { ThemeProvider } from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";

import theme from "../themes/theme";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import useWindowSize from "../hooks/windowSize";
import GlobalStyles from "../common/GlobalStyles";
import CookieBar from "../components/CookieBar";

interface IProps {
  headerLocation: string
  skipFooter?: boolean;
  skipFooterBorder?: boolean;
  footerBorderOnMobile?: boolean;
  isIntro?: boolean;
  children?: ReactNode;
  intercomExtraPadding?: boolean;
  footerMobileMargin?: number;
}

const TABLET_BREAKPOINT = 1024;

const Layout: React.FC<IProps> = (props) => {
  const {
    headerLocation,
    children,
    isIntro,
    skipFooter,
    skipFooterBorder,
    intercomExtraPadding,
    footerBorderOnMobile,
    footerMobileMargin,
  } = props;

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (!windowWidth) return;

    const SmoothScroll = require("smooth-scroll");

    const offset = windowWidth >= TABLET_BREAKPOINT ? 125 : 70;

    const smoothScroll = new SmoothScroll('a[href*="#"]', {
      speed: 1000,
      offset,
    });

    return () => smoothScroll.destroy();
  }, [windowWidth]);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => {
        return (
          <ThemeProvider theme={theme}>
            <GlobalStyles intercomExtraPadding={intercomExtraPadding} />
            {isIntro ? null : <Header location={headerLocation} />}
            <Main isFullHeight={isIntro}>{children}</Main>
            {isIntro || skipFooter ? null : (
              <Footer
                skipBorder={skipFooterBorder}
                borderOnMobile={footerBorderOnMobile}
                mobileMargin={footerMobileMargin}
              />
            )}
            <CookieBar
              cookieExpire={150}
              cookieName="flatworld"
              buttonText="I agree!"
              contentText={
                <>
                  We use cookies to improve your experience on this site. Read
                  our{" "}
                  <Link to="/cookies/" target="_blank">
                    policy here
                  </Link>
                  .
                </>
              }
            />
          </ThemeProvider>
        );
      }}
    />
  );
};

export default Layout;
