import styled from 'styled-components';
import { rgba } from 'polished';

import { media } from './../../common/MediaQueries';
import { transition } from './../../common/mixins';

const CookieBarStyled = styled.div`
  ${transition};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.watermelon, 0.8)};
  z-index: 100;

  &:hover {
    background-color: ${({ theme }) => theme.colors.watermelon};
  }

  .CookieConsent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    padding: 1rem 0;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;

    @media ${media.phone} {
      flex-direction: row;
      align-items: center;
    }

    > div {
      @media ${media.phone} {
        align-self: center;
      }
    }

    .cookiesConsent__text {
      font-size: 12px;
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      margin-bottom: 10px;

      @media ${media.phone} {
        font-size: 14px;
        margin-bottom: 0;
      }

      a {
        color: inherit;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .cookieConsent__button {
      ${transition};
      display: inline-flex;
      align-items: center;
      flex-shrink: 0;
      color: ${({ theme }) => theme.colors.white};
      border-radius: 30px;
      height: 30px;
      position: relative;
      background-color: ${({ theme }) => theme.colors.main};
      padding: 0px 10px;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      font-size: 10px;
      padding: 5px 16px;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.main};
      }

      @media ${media.phone} {
        font-size: 12px;
        margin-left: 1.5rem;
        height: 40px;
      }
    }
  }
`;

export { CookieBarStyled };
