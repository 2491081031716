import React from "react";

import { StyledCopyrights, StyledCopyrightsBody } from "./styles";
import { Typo14 } from "../Typography/index";

interface Props {
  text: any;
}

const Copyrights = ({ text }: Props) => {
  return (
    <StyledCopyrights>
      <StyledCopyrightsBody>
        <Typo14 fontWeight="300" color="dark">
          {text}
        </Typo14>
      </StyledCopyrightsBody>
    </StyledCopyrights>
  );
};

export default Copyrights;
