import React from 'react';
import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

interface Props {}

const Link = styled.a`
  display: block;
  width: 100%;
  padding: 12px 32px;
  background: ${({ theme }) => theme.colors.turquoise};
  color: white;
  border-radius: 32px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  flex-shrink: 0;
  text-align: center;
  font-weight: 500;

  &:hover {
    background: #0bf4b3;
  }

  &:active {
    background: #0adca1;
  }

  @media ${media.tablet} {
    width: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const HeaderCTA: React.FC<Props> = () => {
  return (
    <Link href={`https://${process.env.GATSBY_PUBLIC_DOMAIN}/contact`} target="_blank">
      Let&apos;s talk
    </Link>
  );
};

export default HeaderCTA;
