import { Link } from "gatsby";
import React from "react";
import { IUrlItem } from "../../interfaces/IUrlItem";

import { MainNavLinkStyled } from "./styles";

interface Props { 
  item: IUrlItem
  active: boolean
  childActive: boolean
}

const MainNavItem: React.FC<Props> = ({ active, childActive, item }) => {
  const { title, url, target } = item;

  if(url.indexOf('https://') === -1 || url.indexOf('http://') === -1) {
    return (
      <Link to={url}>
        <MainNavLinkStyled
          as="span"
          $childActive={childActive}
          $active={active}
        >
          {title}
        </MainNavLinkStyled>
      </Link>
    );
  }

  return (
    <MainNavLinkStyled 
      href={url}
      $childActive={childActive}
      $active={active}
      target={target}
    >
      {title}
    </MainNavLinkStyled>
  );
};

export default MainNavItem;
