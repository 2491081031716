import { Link } from 'gatsby';
import React from 'react';
import { IUrlItem } from '../../interfaces/IUrlItem';
import { SubMenuWrapper, SubMenuLink } from './styles';

interface Props { 
  items: IUrlItem[]
  location: string
}

const SubMenu : React.FC<Props> = props => {

  const {
    items,
    location,
  } = props;

  return (
    <SubMenuWrapper>
      {items.map(item => {
        const { id, url, title, target } = item; 

        if (url.indexOf('https://') !== -1 ) {
          return (
            <SubMenuLink 
              key={`${title}${url}`}
              href={url}
              active={id === location}
              target={target}
            > 
              {title}
            </SubMenuLink>
          );
        }

        return (
          <Link 
            to={url}
            key={`${title}${url}`}
          >
            <SubMenuLink 
              active={id === location}
              as="span"
            > 
              {title}
            </SubMenuLink>
          </Link>
        );
      })}
    </SubMenuWrapper>
  );
}

export default SubMenu;