import styled, { css } from 'styled-components';
import { color, background } from 'styled-system';
import { customMedia, media } from './../common/MediaQueries';

interface InnerProperties {
  isRelative?: boolean;
  isSmall?: boolean;
  isMedium?: boolean;
  noOffsetMobile?: boolean;
  noOffsetTablet?: boolean;
  fullScreenMobile?: boolean;
  isBlog?: boolean;
  bg?: string;
}

const Inner = styled.div<InnerProperties>`
  ${color};
  ${background};
  width: 100%;
  margin: 0 auto;
  padding-left: ${({ theme }) => theme.sizes.offset};
  padding-right: ${({ theme }) => theme.sizes.offset};
  max-width: ${({ theme }) => theme.sizes.inner};

  ${({ fullScreenMobile }) =>
    fullScreenMobile
      ? css`
          width: auto;
          margin: 0 -25px;
        `
      : ''}

  ${({ isRelative }) =>
    isRelative &&
    css`
      position: relative;
      z-index: 1;
    `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      max-width: ${({ theme }) => theme.sizes.innerSm};
    `}

  ${({ isMedium }) =>
    isMedium &&
    css`
      max-width: ${({ theme }) => theme.sizes.innerM};
    `}

  ${({ noOffsetMobile }) =>
    noOffsetMobile &&
    css`
      @media ${customMedia.maxPhone} {
        padding: 0;
      }
    `}

    ${({ noOffsetTablet }) =>
    noOffsetTablet &&
    css`
      @media ${customMedia.maxTablet} {
        padding: 0;
      }
    `}

    ${({ isBlog }) =>
    isBlog &&
    css`
      max-width: 960px;
    `}

    ${({ fullScreenMobile }) =>
    fullScreenMobile
      ? css`
          @media ${media.phone} {
            margin: 0 auto;
          }
        `
      : ''}
`;

export default Inner;
