import styled, { css } from 'styled-components';

import Container from './../Container';
import { media, customMedia } from './../../common/MediaQueries';
import { transition } from './../../common/mixins';

interface IHeaderWrapperStyled {
  isPageScrolled: boolean;
}

const HeaderWrapperStyled = styled.header<IHeaderWrapperStyled>`
  ${transition}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  z-index: ${({ theme }) => theme.zIndex.index10};

  @media ${customMedia.maxTablet} {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  }

  @media ${media.tablet} {
    height: auto;
  }

  ${({ isPageScrolled }) =>
    isPageScrolled &&
    css`
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    `}
`;

const HeaderStyled = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  width: 100%;

  @media ${media.tablet} {
    padding: 0;
    align-items: flex-end;
  }
`;

const HeaderLeftStyled = styled.div`
  width: auto;

  @media ${media.tablet} {
    padding-bottom: 17px;
    padding-top: 18px;
  }
`;

const HeaderRightStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto;
`;

const HeaderLogoStyled = styled.div`
  z-index: ${({ theme }) => theme.zIndex.index10};
`;

const HeaderNavigationWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

interface IHeaderNavigationStyled {
  isOpened: boolean;
}

const HeaderNavigationStyled = styled.div<IHeaderNavigationStyled>`
  display: flex;
  transition: 500ms cubic-bezier(0.19, 1, 0.22, 1) 200ms;
  z-index: 2;

  @media ${customMedia.maxTablet} {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 53px;
    left: -100vw;
    width: 100%;
    height: calc(100 * var(--vh) - 53px);
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    background-color: ${({ theme }) => theme.colors.white};
    transform: translateX(0);

    ${({ isOpened }) =>
      isOpened &&
      css`
        transform: translateX(100vw);
        visibility: visible;
        opacity: 1;
      `}
  }

  @media ${media.tablet} {
    transition: none;
    justify-content: center;
  }
`;
const HeaderNavigationWrapStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media ${customMedia.maxTablet} {
    width: 100%;
    flex-direction: column;

    margin: 1.5rem 0 0;
    max-height: calc(100% - 50px);
    overflow: auto;
  }

  @media ${media.tablet} {
    align-items: stretch;
  }
`;

const HeaderNavigationStyledMenu = styled.div`
  width: 100%;

  @media ${customMedia.maxTablet} {
    margin-bottom: 12px;
  }

  @media ${media.tablet} {
    align-self: flex-end;
  }
`;

const HeaderNavigationStyledSocial = styled.div`
  flex-shrink: 0;
  width: 100%;
  padding: 0 20px;

  @media ${customMedia.maxTablet} {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  @media ${media.tablet} {
    width: auto;
    padding: 15px 0;
  }
`;

const HeaderNavigationStyledAction = styled.div`
  display: flex;
  justify-content: center;

  @media ${media.tablet} {
    display: none;
  }
`;

export {
  HeaderWrapperStyled,
  HeaderStyled,
  HeaderLeftStyled,
  HeaderRightStyled,
  HeaderLogoStyled,
  HeaderNavigationWrapperStyled,
  HeaderNavigationStyled,
  HeaderNavigationStyledMenu,
  HeaderNavigationStyledSocial,
  HeaderNavigationWrapStyled,
  HeaderNavigationStyledAction,
};
