import { useState, useEffect, useRef } from "react";

const useWindowSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const timeout = useRef<any | null>(null);

  const lastSize = useRef({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      timeout.current && clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (
          lastSize.current.width === windowWidth &&
          lastSize.current.height === windowHeight
        )
          return;

        lastSize.current = { width: windowWidth, height: windowHeight };

        setSize({ width: windowWidth, height: windowHeight });
      }, 100);
    };

    window.addEventListener("resize", handleResize, { passive: true });

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
};

export default useWindowSize;
