import styled, { css } from 'styled-components';
import Link from 'gatsby-link';
import { media } from '../../common/MediaQueries';

const LogoLinkStyled = styled(Link)`
  display: block;
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.8;
  }
`;

interface LogoProps {
  big?: boolean;
}

const LogoIconStyled = styled.span<LogoProps>`
  display: block;
  flex-shrink: 0;

  ${({ big }) =>
    big
      ? css`
          width: 80px;
          height: 45px;
        `
      : css`
          width: 56px;
          height: 29px;
        `}

  @media ${media.tablet} {
    ${({ big }) =>
      big
        ? css`
            width: 96px;
            height: 56px;
          `
        : css`
            width: 68px;
            height: 35px;
          `}
  }

  svg {
    height: 100%;
    fill: currentColor;
    overflow: hidden;
  }
`;

export { LogoLinkStyled, LogoIconStyled };
